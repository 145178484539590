/*
 * Lazy loads content when scroll target is above the viewport
 */
(function ($) {
    var $window = $(window);

    $('[data-lazy-content]').each(function () {
        var $this = $(this),
            targetSelector = $this.data('lazy-content-target-selector'),
            $scrollTarget = $(targetSelector),
            contentLoaded = false;

        var loadContent = function () {
            if (contentLoaded === true || (targetSelector && $scrollTarget.offset().top > $window.scrollTop()))
                return;

            contentLoaded = true;

            $.get($this.data('lazy-content-url')).done(function (result) {
                $this.html(result);

                //if the element has an event to trigger, fire it on the element to start
                var trigger = $this.data('lazy-loaded-trigger');
                if (trigger) {
                    $this.trigger(trigger);
                }
            });
        };

        if (!targetSelector) {
            loadContent();
        }
        else {
            $window.on('scroll resize', loadContent);
        }
    });
})(jQuery);