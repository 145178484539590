/// <reference path="../framework/evo.js" />
(function ($) {
    var parentSelector = '.responsive-banner-hit-area-container',
        galleryContainerSelector = '.gallery-container',
        activeToolTipClass = 'active',
        activeToolTipBodyClass = 'see-click-buy-active';

    evo.$.body.on('click', '.js-banner-tool-tip', function (e) {
        e.stopPropagation();
        var $this = $(this),
            galleryContainerLeftPositionArray = $(galleryContainerSelector).map(function () { return $(this).scrollLeft(); });

        if (!$this.parent(parentSelector).hasClass(activeToolTipClass)) {
            var relativeTopPosition = this.getBoundingClientRect().top;

            // Display tooltip panel below hot spot if it is going to overlap with the masthead
            $this.parent(parentSelector).toggleClass('tooltip-below', ((relativeTopPosition - $this.next('.repsonsive-banner-tool-tip').outerHeight()) < $('#masthead').outerHeight()));

            // Deactivate any other active tooltips
            $('.js-banner-tool-tip').parent(parentSelector).removeClass(activeToolTipClass);
        }

        // Toggle the tooltip panel
        $this.parent(parentSelector).toggleClass(activeToolTipClass);

        // Toggle the body active class
        evo.$.body.toggleClass(activeToolTipBodyClass, $this.parent(parentSelector).hasClass(activeToolTipClass));

        // If mobile, set current left position of all .gallery-container elements after the body class has
        // been added which sets the gallery-container overflow to initial.
        if (window.outerWidth < evo.mq.screenSmMin) {
            $(galleryContainerSelector).each(function (index) {
                $(this).css({ right: galleryContainerLeftPositionArray[index] });
            });
        }
    }).on('click', '.repsonsive-banner-tool-tip', function (e) {
        e.stopPropagation();
    }).on('click', '.js-tool-tip-close', function (e) {
        $(this).closest(parentSelector).removeClass(activeToolTipClass);
        evo.$.body.removeClass(activeToolTipBodyClass);
        $(galleryContainerSelector).css({ right: 0 });
    });

    evo.$.html.on('click touch', '.see-click-buy-active', function (e) {
        $(parentSelector).removeClass(activeToolTipClass);
        evo.$.body.removeClass(activeToolTipBodyClass);
        $(galleryContainerSelector).css({ right: 0 });
    });
})(jQuery);