/// <reference path="../framework/evo.js" />
/*
 * Support for product thumb compare checkboxes
 */
(function ($) {
    var listManager = new evo.CompareItems.ListManager(evo.storage);

    //Helper method that gets the page title without the "evo |" prefix
    function getPageTitle() {
        var title = document.title;

        return title.substr(0, title.indexOf(' | '));
    }

    //Helper method that gets the document url (with a possibility of getting the hashed url)
    function getUrl() {
        var url = location.href;
        url = url.substr(url.indexOf(location.pathname));

        var indexOfHash = url.indexOf('#');
        if (indexOfHash > -1) {
            url = url.substr(0, indexOfHash);
        }
        return url;
    }

    //Handler for the change event of compare checkboxes
    evo.$.body.on('change', '.js-compare-cbx', function () {
        var $this = $(this),
            productId = $this.val();

        if ($this.is(':checked')) {
            listManager.addProduct(productId);
            listManager.setUrl(getUrl());
            listManager.setTitle(getPageTitle());

            // If this is a pdp page for Out of Stock or Preview product
            // include the OOS/Preview product ID in the set to compare.
            var pdpOosPreviewHidden = $('#pdp-compare-hdn-oos-preview');
            // Need the array of product ids in the Add to Cart related products so the oos/preview product will only be addeed for them.
            var pdpAtcDiffYearProductIds = $('.pdp-buy-grid-related .js-compare-cbx').map(function () { return this.value; }).toArray();

            if (pdpOosPreviewHidden.length > 0 && $.inArray(productId, pdpAtcDiffYearProductIds) > -1) {
                listManager.addProduct(pdpOosPreviewHidden.val());
            }
        } else {
            listManager.removeProduct(productId);
        }
    });

    // Control the behavior of the "View Selections" link.
    // The link is not visible until the Compare checkbox is selected
    // If only one item has been selected to compare display a validation
    // error message that at least one more item needs to be selected.
    // If two or more items are selected then redirect the customer to
    // the compare page.
    evo.$.body.on('click', '.js-compare-link', function (event) {
        event.preventDefault();

        var $this = $(this);

        if (listManager.compareList.length > 1) {
            window.location.replace($this.attr("data-compare-url"));
        } else {
            $this.siblings(".compare-validation-message").show().delay(2000).fadeOut("slow");
        }
    });

    //Helper method that initializes all the compare boxes that have already been selected
    //by the user
    function initializeCompareBoxes() {
        var selector = '.js-compare-product-' + listManager.compareList.join(',.js-compare-product-');
        $(selector).not(':checked').prop('checked', 'checked');
    }

    evo.$.body.on('initializeCompareBoxes', initializeCompareBoxes);
    evo.$.body.on('productadded.evo.compare', function (e, data) {
        if (data.fromStorage) {
            $('.js-compare-product-' + data.id).prop('checked', 'checked');
        }
    });
    evo.$.body.on('productremoved.evo.compare', function (e, data) {
        if (data.fromStorage) {
            $('.js-compare-product-' + data.id).prop('checked', null);
        }
    });
    initializeCompareBoxes();
})(jQuery);