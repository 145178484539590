/// <reference path="../libs/jquery.ba-throttle-debounce.js" />
/*
 * Responsible for all things reviews (utilizing Power Reviews' render() method)
 *
 * Example usage: Use Html.ReviewContainter helper class
 * @Html.ReviewContainer(123456, "ReviewDisplay")
 * will output
 * <div class="js-review-container" data-product-id="123456" data-component="ReviewDisplay"></div>
 *
 * Elements added to the DOM after page load should be followed with the following script:
 * <script type="text/javascript">evo.renderReviews()</script>
 *
 * Advanced options may be passed using the "options" data attribute:
 * Example usage: Use Html.ReviewContainter helper class
 * @Html.ReviewContainer(123456, "ReviewDisplay", { REVIEW_DISPLAY_LIST_TYPE = 'CONDENSED' })
 * will output
 * <div class="js-review-container" data-product-id="123456" data-component="ReviewDisplay" data-options="{REVIEW_DISPLAY_LIST_TYPE:'CONDENSED'}"></div>
 */

(function ($) {
  var idSeed = 0;
  function CreateId() {
    return "review-container-unique-" + ++idSeed;
  }

  // Refactored callback function provided by PowerReviews for SEO purposes
  // See Option 2: https://help.powerreviews.com/Content/SEO/Enhanced%20SEO.htm?Highlight=seo
  function CreateSchema(_, data) {
    if (!data || !data.average_rating || !data.review_count) {
      return;
    }

    var aggregateRating = {
      "@type": "AggregateRating",
      ratingValue: data.average_rating,
      reviewCount: data.review_count,
    };

    var scriptTag = $("#product-schema");
    if (!scriptTag.size()) {
      console.warn("product schema is not available");
      return;
    }
    var serializedItemSchemaObject = JSON.parse(scriptTag.html());

    serializedItemSchemaObject["aggregateRating"] = aggregateRating;
    scriptTag.html(JSON.stringify(serializedItemSchemaObject));
  }

  //helper method that creates all the render objects needed for
  //Power Reviews' display code for all the review containers on the page
  function renderReviewContainers() {
    var unrenderedContainerCount = 0;
    var containers = $(".js-review-container");
    var renderObjects = containers
      .map(function (_, container) {
        var $container = $(container);

        //keep track of how many containers have not yet been rendered
        //to optimize future calls to this method
        if (!$container.data("rendered")) {
          unrenderedContainerCount++;
          $container.data("rendered", true);

          //each container must have a unique id since
          //it is referenced by the render settings below
          if (!$container.attr("id")) {
            $container.attr("id", CreateId());
          } else {
            $container.empty();
          }
        }

        //default settings for all PR render objects
        let renderSettings = {
          api_key: "e5fcb978-8192-44d7-8fd1-b4e14fd1a523",
          locale: "en_US",
          merchant_group_id: "10021",
          merchant_id: "4163",
          page_id: "" + $container.attr("data-product-id"),
          enable_content_collection_modal: false,
          components: {},
          style_sheet:
            "https://static.evo.com/content/www/css/power-reviews-overridesV1.css",
        };

        //specify the render component for the container's id
        renderSettings.components[$container.attr("data-component")] =
          $container.attr("id");

        if ($container.attr("data-component") === "ReviewSnippet") {
          renderSettings.on_render = CreateSchema;
        }

        //if there are any extra options specified by the container
        //merge those into our settings
        renderSettings = $.extend(renderSettings, $container.data("options"));

        return renderSettings;
      })
      .get();

    if (unrenderedContainerCount > 0) {
      //ensure the power reviews code is loaded
      jQuery
        .ajax({
          url: "//ui.powerreviews.com/stable/4.1/ui.js",
          dataType: "script",
          cache: true,
        })
        .done(function () {
          //empty out elements that have already been rendered
          //power reviews doesn't do this for us
          containers.empty();

          if (renderObjects.length === 1) {
            renderObjects = renderObjects[0];
          }

          //PowerReview's asynchronous initialization method for rendering reviews. https://help.powerreviews.com/Content/Implementation/Reviews-PDP.htm
          window.pwr =
            window.pwr ||
            function () {
              (pwr.q = pwr.q || []).push(arguments);
            };
          pwr("render", renderObjects);
        });
    }
  }

  //setup the renderReviews method using a debouncer
  //so that the method can be called multiple times
  //but its execution is limited per "burst"
  evo.renderReviews = $.debounce(50, renderReviewContainers);

  //when the page first loads, we need to render
  //any review containers that exist on the page
  evo.renderReviews();
})(jQuery);
