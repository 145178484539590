/// <reference path="../libs/jquery.ba-throttle-debounce.js" />
/// <reference path="../libs/scrolltoid.js" />

/*top Anchor
appends an anchor link to the body which detects whether or now it needs to be in fixed position and smooth
scrolls the user to the top of the page.
*/

(function ($) {
    $(function () {
        if ($("#top-anchor").length)
            return;

        $('#wrapper').append('<a href="#masthead" id="top-anchor" aria-label="Scroll to Top" class="top-anchor icon-arrow-up-large"></a>');

        var winHeight = evo.$.window.height(),
            isVisible = false,
            $topAnchor = $('#top-anchor'),
            scrollTop = 0;
        evo.$.window.scroll($.throttle(250, function () {
            scrollTop = $(this).scrollTop();
            if (scrollTop > winHeight && !isVisible) {
                $topAnchor.addClass('active');
                isVisible = true;
            }
            else if (scrollTop < winHeight && isVisible) {
                $topAnchor.removeClass('active');
                isVisible = false;
            }
        }));

        $topAnchor.scrollToID({
            onComplete: function () {
                $('#masthead').focus();
            }
        });
    });
})(jQuery);