/// <reference path="../libs/modernizr.js" />

(function($) {
    var $navigation = $('#navigation');

    //triggers the mobile navigation
    function bindMobileNavigation() {
        var $html = evo.$.html,
            $window = evo.$.window,
            $body = evo.$.body,
            $page = $('.page'),
            $mobileNavLink = $('#mobile-nav-link'),
            $mobileNavLabel = $('#mobile-nav-label'),
            mobileNavLabelText = $mobileNavLabel.text(),
            mobileNavCloseLabel = $mobileNavLabel.data('close-label');

        function toggleSubnav() {
            if ($html.hasClass('navigation-active')) {
                hideSubnav();
            } else {
                showSubnav();
            }
        }

        function showSubnav() {
            $body.trigger('mobileNavigation.open');
            $html.addClass('navigation-active navigation-visible');
            $mobileNavLabel.text(mobileNavCloseLabel);
        };

        function hideSubnav() {
            $body.trigger('mobileNavigation.close');
            $html.removeClass('navigation-active');

            var t = setTimeout(function() {
                    $html.removeClass('navigation-visible');
                    $body.trigger('mobileNavigation.closeSubnavigation');
                },
                400);
            $mobileNavLabel.text(mobileNavLabelText);
        };

        $mobileNavLink.on('touchend',
            function(e) {
                toggleSubnav();
                e.preventDefault();
                e.stopPropagation();
            });

        $mobileNavLink.on('click',
            function(e) {
                toggleSubnav();
                e.preventDefault();
                e.stopPropagation();
            });

        $page.on('touchend',
            function(e) {
                if (!$html.hasClass('navigation-active')) {
                    return;
                }
                hideSubnav();
                e.preventDefault();
                e.stopPropagation();
            });

        $page.on('click',
            function(e) {
                if (!$html.hasClass('navigation-active')) {
                    return;
                }
                hideSubnav();
                e.preventDefault();
                e.stopPropagation();
            });

        //need to close the menu when we rotate the page on tablets
        $window.on('orientationchange',
            function(e) {
                hideSubnav();
            });
    };

    //shows and hides mobile search and sets a session variable.
    function bindMobileSearch() {
        var $mastheadSearchInput = $('#txt-search'),
            $mastheadSearchClear = $('#masthead-search-clear');

        $mastheadSearchInput.on('keyup',
            function() {
                $mastheadSearchClear.toggleClass('active', $mastheadSearchInput.val().length > 0);
            });

        $mastheadSearchClear.on('click',
            function() {
                $mastheadSearchInput.val('');
                $mastheadSearchClear.removeClass('active');
            });
    };

    function bindMobileBack() {
        var $navigationWrapper = $('#navigation-wrapper');

        $navigationWrapper.on('click',
            '.js-subnav-back-link',
            function(e) {
                e.preventDefault();
                evo.$.body.trigger('mobileNavigation.closeSubnavigation');
            });
    };

    $(function() {
        bindMobileSearch();
        bindMobileNavigation();
        bindMobileBack();

        //handler for login/account creation
        //refresh the page after a few seconds so that the user's cart/order will be refreshed
        evo.$.body.on('authenticated.evo.logon created.evo.logon',
            function() {
                window.setTimeout(function() {
                        window.location.reload(true);
                    },
                    750);
            });

        window.requestIdleCallback(function() {
            $(".js-mega-menu").accessibleMegaMenu({
                /* css class used to define the megamenu styling */
                menuClass: "nav-menu",

                /* css class for a top-level navigation item in the megamenu */
                topNavItemClass: "top-nav-item",

                /* css class for a megamenu panel */
                panelClass: "sub-nav-group",

                /* css class for a group of items within a megamenu panel */
                panelGroupClass: "subnavcol-li",

                /* css class for the hover state */
                hoverClass: "acc-hover",

                /* css class for the focus state */
                focusClass: "acc-focus",

                /* css class for the open state */
                openClass: "acc-active"
            });
        });
    });
})(jQuery);