/// <reference path="../framework/evo.js" />
/// <summary>
/// global handler for google analytics tracking
/// </summary>
/// <remarks>
/// any element with data-tack="action-label" will be tracked
/// Dynamic labels can be extracted by referring to one of the following
/// 1. href => sets the label to the href attribute value
/// 2. val => sets the label to the input value
/// 3. text => sets the label to the text
///
/// Dynamic labels can refer to other elements by setting the "data-tracktarget" attribute
/// </remarks>
(function ($) {
    evo.tracking = evo.tracking || {};

    // TODO: remove references to public tracking, funnel all tracking through this
    var GACategory = 'Global',
        Actions = {
            'c': 'Click'
        };

    // Set current page tracking Category
    evo.tracking.setCategory = function (category) {
        if (category) {
            GACategory = category;
        }
    };

    // set valid action map for current page
    evo.tracking.setActions = function (actions) {
        if (actions) {
            $.extend(Actions, actions);
        }
    };

    // Record custom tracking element
    evo.tracking.track = function (category, action, label, value, nonInteractive) {
        category = category || GACategory;
        action = Actions[action] || action;

        return evo.trackGAEvent(category, action, label, value, nonInteractive);
    };

    // Bind tracking events
    evo.tracking.bindTracking = function (parent) {
        $(parent).find('[data-track],[data-track] a').not('form,div,body').on('click', function () {
            trackElement(this);
        });
    };

    // parse and record tracking information from Dom element
    function trackElement(element) {
        var $this = $(element),
            trackVal = $this.data('track') || $this.closest('[data-track]').data('track'),
            vals = trackVal && trackVal.split('-'),
            action = vals && vals.shift(),
            label = vals && vals.join('-'),
            target = $this.data('tracktarget');

        var $target = target ? $(target) : $this;

        // Allow dynamic label values
        switch (label) {
            case 'href':
                label = $target.attr('href');
                break;
            case 'text':
                label = $target.text();
                break;
            case 'val':
                label = $target.val();
                break;
        }

        if (action && Actions[action]) {
            evo.tracking.track(GACategory, action, label);
        }
        else {
            // check for console before using (IE9)
            window.console && console.warn(action + ' is not a defined tracking action for ' + GACategory);
        }
    }

    $(function () {
        evo.tracking.bindTracking(evo.$.body);
        evo.$.body.on('validationfailed.evo.validation', function (e) {
            trackElement(e.target);
        });
    });
})(jQuery);