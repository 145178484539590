(function ($) {
    var selectors = {
        countdown: '.js-countdown-timer',
        endTime: 'end-time',
        hours: '.js-countdown-hours',
        minutes: '.js-countdown-minutes',
        seconds: '.js-countdown-seconds',
        message: 'message',
        startTime: 'start-time'
    };

    function toTwoDigits(number) {
        number = number.toString();
        return number.length > 1 ? number : '0' + number;
    }

    $.fn.evoCountdown = function () {
        $(this).not('.loaded').addClass('loaded').each(function () {
            var $this = $(this),
                start = new Date($this.data(selectors.startTime)),
                end = new Date($this.data(selectors.endTime)),
                message = $this.data(selectors.message),
                now = new Date();

            $this.hide();

            if (now > end) {
                return;
            }

            var int;

            var setDisplay = function () {
                var now = new Date();

                if (now < start) {
                    $this.hide();
                    return;
                }

                if (now > end) {
                    window.clearInterval(int);
                    $this.hide();
                    $this.trigger('time-elapsed.evo.countdown', { start: $this.data(selectors.startTime), end: $this.data(selectors.endTime), message: $this.data(selectors.message) });
                    return;
                }

                var diff = new Date(end - now),
                    hours = diff.getUTCHours(),
                    mins = diff.getUTCMinutes(),
                    secs = diff.getUTCSeconds();

                $this.find(selectors.hours).html(toTwoDigits(hours));
                $this.find(selectors.minutes).html(toTwoDigits(mins));
                $this.find(selectors.seconds).html(toTwoDigits(secs));
                $this.find(selectors.messageContainer).html(message);

                $this.show();
            }

            int = window.setInterval(setDisplay, 500);

            setDisplay();
        });
    }

    $(selectors.countdown).evoCountdown();
})(jQuery);