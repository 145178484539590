/*
* Based on rwdImageMaps jQuery plugin v1.5, but heavily rewritten
*/
(function ($) {
    $.fn.rwdImageMaps = function () {
        var recalcHandles = [];

        $(this).filter('img[usemap]').each(function () {
            var img = $(this),
                mapName = img.attr('usemap').replace('#', ''),
                maxWidth = img.attr('width'),
                maxHeight = img.attr('height');

            var recalculateCoords = function () {
                var width = img.width(),
                    height = img.height(),
                    wPercent = width / 100,
                    hPercent = height / 100,
                    c = 'coords';

                if (img.data('width') === width && img.data('height') === height) {
                    return;
                }

                img.data('width', width);
                img.data('height', height);

                $('map[name="' + mapName + '"] area').each(function () {
                    var area = $(this);

                    if (!area.data(c)) {
                        area.data(c, area.attr(c));
                    }

                    var coords = area.data(c).split(','),
                        coordsPercent = new Array(coords.length);

                    for (var i = 0; i < coordsPercent.length; ++i) {
                        if (i % 2 === 0) {
                            coordsPercent[i] = parseInt(((coords[i] / maxWidth) * 100) * wPercent, 10);
                        } else {
                            coordsPercent[i] = parseInt(((coords[i] / maxHeight) * 100) * hPercent, 10);
                        }
                    }
                    area.attr(c, coordsPercent.join(','));
                });
            }

            recalcHandles.push(recalculateCoords);

            if (!maxWidth || !maxHeight) {
                var temp = new Image();
                temp.onload = function () {
                    if (!maxWidth) {
                        maxWidth = temp.width;
                    }
                    if (!maxHeight) {
                        maxHeight = temp.height;
                    }
                    recalculateCoords();
                }
                temp.src = img.attr('src');
            } else {
                recalculateCoords();
            }
        });

        var timeoutId;
        $(window).resize(function () {
            if (timeoutId) {
                window.clearTimeout(timeoutId);
                timeoutId = null;
            }
            timeoutId = window.setTimeout(function () {
                for (var i = recalcHandles.length - 1; i >= 0; i--) {
                    recalcHandles[i]();
                }
            }, 100);
        });

        return this;
    };
})(jQuery);