/// <reference path="../libs/jquery.cookie.js" />
/*
Known Dependencies: des/depageheader.ascx
Requirements: none

Checks cookies for the status of the current user (logged in/out, cart profile, etc.)
Also triggers an event for the compare items being loaded, which is used in the compare.js and itemresults.js
*/
(function ($) {
    $('#evo-search-form').submit(function (e) {
        if (!$('#txt-search').val()) {
            e.preventDefault();
            alert('Please type a phrase to search.');
        }
    });
    
    //configuration options listed here:
    //https://tarekraafat.github.io/autoComplete.js/
    const autoCompleteJS = new autoComplete({
            selector: "#txt-search",
            placeHolder: "Search brands and products",
            data: {
                src: async (query) => {
                  try {
                      if (!query) {
                          return [];
                      }

                    // Fetch Data from external Source
                    const source = await fetch(`${$.ApplicationPath()}/results/suggest?phrase=${encodeURI(query.trim())}`);
                    // Data should be an array of `Objects` or `Strings`
                    const data = await source.json();

                    return data;
                  } catch (error) {
                    console.warn(error);
                    return [];
                  }
                },
                keys: ["pageTitle"]
            },
            diacritics: true,
            resultItem: {
                highlight: true
            },
            resultsList: {
                maxResults: 20
            },
            submit: true,
            threshold: 2,
            searchEngine: 'loose',
            events: {
                input: {
                    open: (_) => {
                        window.dataLayerManager.push({
                            'event': 'Autocomplete PanelOpened',
                            'searchTerm': autoCompleteJS.input.value
                        });
                    },
                    selection: (event) => {
                        const selection = event.detail.selection;
                        window.dataLayerManager.push({
                            'event': 'Autocomplete Selection',
                            'searchTerm': autoCompleteJS.input.value,
                            'searchTerm-Auto': selection.value.pageTitle,
                            'suggestionRank': selection.index + 1
                        });
                        autoCompleteJS.input.value = selection.value.pageTitle;
                        window.location.href = $.ApplicationPath() + selection.value.relativePath;
                    }
                }
            }
        });

    $('#util-account').on('updateHeader', function (evnt, response) {
        if (!response) {
            response = {
                CartItemCount: 0
            };

            var cartStatus = $.cookie('Cart');
            if (cartStatus) {
                var cartObject = JSON.parse(cartStatus);
                response.CartItemCount = parseInt(cartObject.C, 10);
            }
        } else {
            $('#cart-count').html(response.CartItemCount);
        }

        $(document.body).trigger('itemsToCompareLoaded');
    }).trigger('updateHeader');
}(jQuery));