(function ($) {
    function setSizeChartOverflowIndicator() {
        var $overflowTables = $('.table-responsive');

        $overflowTables.each(function () {
            var $this = $(this),
                $sizeChart = $this.find('.sizeChart');

            //wrap each responsive table with a wrapper
            $this.wrap('<div class="table-responsive-wrapper"></div>');
            var $overflowWrapper = $this.parent();

            //Apply the overflow styling class to each instance that has a table which overflows
            if ($sizeChart.width() > $this.width()) {
                $overflowWrapper.addClass('table-overflow');

                //If the table is taller than the viewport height, apply a class to control the vertical positioning of the indicator
                if ($sizeChart.height() > $(window).height()) {
                    $overflowWrapper.addClass('table-overflow-tall');
                }
            }

            $this.on('scroll', function () {
                $overflowWrapper.removeClass('table-overflow');
            });
        });
    }

    //Run on document ready
    $(setSizeChartOverflowIndicator);

    evo.$.body.on('modal-html-loaded', function () {
        $(setSizeChartOverflowIndicator);
    });
})(jQuery);