(function ($) {
    var $navigation = $('#navigation'),
        $navigationOverlay = $('#navigation-overlay'),
        $navigationWrapper = $('#navigation-wrapper'),
        $subnavs,
        $subnavigationWrapper = $('#subnavigation-wrapper'),
        selectors = {
            active: 'active',
            closeButton: '.subnav-close-btn',
            menuDataUrl: '/layout/subnavigation',
            mobileAccordion: '.js-subnav-accordion',
            navBackground: '.navigation-bg',
            navItem: '.top-nav-has-children',
            navLink: '.top-nav-link',
            storageKey: 'subnavHTML'
        },
        desktopNavDelay = 300;

    function showSubnavigation(navItem) {
        if (evo.isMobileNavigation)
            return;

        $navigationWrapper.addClass(selectors.active);

        var $this = $(navItem),
            height = $this.find('.subnav').outerHeight() || 0;

        $(selectors.navItem).removeClass(selectors.active);
        $this.addClass(selectors.active);
        $navigationOverlay.height(height).css('top', height * -1);
        $this.trackPromos();
    }

    //just handles the sliding up of the subnavigation
    function slideUpSubnavigation() {
        $(selectors.navItem).removeClass(selectors.active);
        $navigationOverlay.height(0);
    }

    function hideSubnavigation() {
        slideUpSubnavigation();
        //turn off the display on the subnav elements after animation completes
        setTimeout(function () {
            $navigationWrapper.removeClass(selectors.active);
        }, desktopNavDelay);
    }

    function closeMobileSubnavigation() {
        var $topNavChildren = $navigation.find(selectors.navItem),
            $subnavAccordion = $navigation.find(selectors.mobileAccordion);

        //close all subav
        evo.$.body.removeClass('subnav-active');

        setTimeout(function () {
            $topNavChildren.removeClass('subnav-show');
            $subnavAccordion.filter('.' + selectors.active).removeClass(selectors.active).next('ul').slideUp();
        }, 350);
    }

    function bindEvents() {
        // On touch, close subnavigation menu if open
        evo.$.body.on('touchend', selectors.navItem + ',' + selectors.navBackground, function (e) {
            var $this = $(this),
                $subnavItem = $(e.target).closest('.subnav');

            if ($subnavItem.length)
                return;

            if ($this.hasClass(selectors.active) || !$this.is(selectors.navItem)) {
                hideSubnavigation();
            }
            else {
                showSubnavigation($this);
            }
            e.stopPropagation();
        });

        // Never follow top level links on touch
        evo.$.body.on('touchend', selectors.navItem + ' a', function (e) {
            if (!evo.isMobileNavigation && $(this).parent().is(selectors.navItem)) {
                e.preventDefault();
            }
        });

        // iOS sends touch and click as separate events :(
        evo.$.body.on('click', selectors.navItem + ' a', function (e) {
            var $parent = $(this).parent();
            if (evo.iOS && $parent.is(selectors.navItem) && !$parent.is('.top-nav-allthings')) {
                return false;
            }
        });

        var showSubnavigationTimeout, slideUpSubnavigationTimeout, hideSubnavigationTimeout;

        // On touch or mouseover, show subnavigation
        evo.$.body.on('mouseenter', selectors.navItem, function (e) {
            var $this = $(this);

            if ($this.attr("data-hover") === 'true')
                return;

            $this.attr("data-hover", true);

            if ($navigation.attr("data-hover") === 'true') { //If the nav is already active, show the new subnav right away
                showSubnavigation($this);
            } else { //else wait the allotted desktopNavDelay before showing the subnav
                clearTimeout(slideUpSubnavigationTimeout);
                clearTimeout(hideSubnavigationTimeout);
                showSubnavigationTimeout = window.setTimeout(function () {
                    if ($this.attr("data-hover") === 'true') {
                        showSubnavigation($this);
                    }
                }, desktopNavDelay);
            }

            e.stopPropagation();
        });

        // Hide subnavigation when no longer hovering
        evo.$.body.on('mouseleave', selectors.navItem, function () {
            var $this = $(this);

            $this.attr("data-hover", false);

            if ($this.attr('data-hover') === 'true') { //If the nav is already active, then hide this subnav right away
                slideUpSubnavigation();
            } else { //else wait the allotted desktopNavDelay before hiding this subnav
                clearTimeout(showSubnavigationTimeout);
                slideUpSubnavigationTimeout = window.setTimeout(function () {
                    if ($this.attr('data-hover') === 'false') {
                        slideUpSubnavigation();
                    }
                }, desktopNavDelay);
            }
        });
        $navigation.on('mouseleave', function () {
            var $this = $(this);

            $this.attr('data-hover', false);

            clearTimeout(showSubnavigationTimeout);
            hideSubnavigationTimeout = window.setTimeout(function () {
                if ($this.attr('data-hover') === 'false') {
                    hideSubnavigation();
                }
            }, desktopNavDelay);
        });

        // Hide subnavigation when close button or background clicked
        evo.$.body.on('click', selectors.closeButton, hideSubnavigation);
        evo.$.body.on('click', selectors.navBackground, hideSubnavigation);

        function navLinkClick(e) {
            var $this = $(this),
                $parent = $this.parent();

            if (!$parent.is(selectors.navItem)) {
                return;
            }

            // Force click of link on desktop to prevent plugins from hijacking menu links
            if (!evo.isMobileNavigation || $this.hasClass('top-nav-mobile-nofollow')) {
                location.href = $this.attr('href');
                return;
            }

            if (evo.iOS && e.type === 'touchend')
                return false;

            e.preventDefault();
            $parent.addClass('subnav-show');
            $this.closest('nav').scrollTop(0);
            evo.$.body.addClass('subnav-active');
            $this.siblings('div:visible').trackPromos();
        }

        function mobileAccordionClick(e) {
            if (!evo.isMobileNavigation)
                return;

            if (evo.iOS && e.type === 'touchend')
                return false;

            e.preventDefault();
            e.stopPropagation();

            var $this = $(this);

            // Hide if active
            if ($this.hasClass(selectors.active)) {
                $this.removeClass(selectors.active).next('ul').slideUp();
            }
            // Show if inactive
            else {
                $(selectors.mobileAccordion).removeClass(selectors.active).next('ul').slideUp();
                $this.addClass(selectors.active).next('ul').slideDown();
            }
        }

        // Mobile Subnav Show
        $(selectors.navLink).on('click', navLinkClick);

        // Mobile subnav accordions
        evo.$.body.on('click', selectors.mobileAccordion, mobileAccordionClick);

        evo.$.body.on('mobileNavigation.closeSubnavigation', closeMobileSubnavigation);
    }

    $(bindEvents);
})(jQuery);