/*
$.scrollToID();
specify a #anchor-link in a link href, make sure
there is a corresponding id in the page.
that's about it.
accepts 'speed' and 'offset' arguments.
*/

(function ($) {
    function scrollToTarget(target, speed, offset, onComplete) {
        $('body, html').animate({
            scrollTop: $(target).offset().top - offset
        }, speed, 'swing', onComplete);
    }

    $('body').on('click', '.js-scroll-to-id', function (e) {
        e.preventDefault();

        var $this = $(this),
            target = '#' + $this.attr('href').split('#').pop(),
            speed = $this.data('scroll-speed') || 500,
            offset = $this.data('scroll-offset') || 20;

        scrollToTarget(target, speed, offset, $.noop);
    });

    $.fn.scrollToID = function (args) {
        var options = {
            speed: 500,
            offset: 20,
            onComplete: $.noop
        };

        $.extend(options, args);

        this.off('click.scrolltoid').on('click.scrolltoid', function (e) {
            e.preventDefault();

            var target = '#' + $(this).attr('href').split('#').pop();
            scrollToTarget(target, options.speed, options.offset, options.onComplete);
        });
    };
})(jQuery);