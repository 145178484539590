(function ($) {
    var keys = {
        compareData: 'CompareProduct',
        compareList: 'CompareItems'
    },
    instances = {
        listManager: null
    }

    window.evo = window.evo || {};

    window.evo.CompareItems = {
        // Start of replacement for compare.js
        // TODO: move more js logic here when making compare page responsive
        'App': function () {
            var self = this,
                storageProvider = evo.storage,
                listManager = instances.listManager || new window.evo.CompareItems.ListManager(storageProvider);

            $.extend(self, {
                products: listManager.compareList,
                getUrl: function () {
                    return listManager.url;
                },
                getTitle: function () {
                    return listManager.title;
                },
                addProduct: listManager.addProduct,
                removeProduct: listManager.removeProduct,
                removeAllProducts: function () {
                    while (self.products.length) self.removeProduct(self.products[0]);
                },
                setTitle: listManager.setTitle,
                setUrl: listManager.setUrl
            });
        },
        // Standalone object to keep track of compare product details
        // monitors storage events to sync information across tabs, and
        // fires custom events to provide a consistent experience to the
        // listeners.
        'ListManager': function (storageProvider) {
            var self = this;

            if (!storageProvider || !storageProvider.getItem || !storageProvider.setItem) throw "evo.CompareItems: must specify a valid storageProvider";

            // Store product id list to storage
            var setProducts = function (idList) {
                storageProvider.setItem(keys.compareList, idList);
            };

            // Initialize local object with title from storage
            var loadTitle = function () {
                var title = storageProvider.getItem(keys.compareList + '[Title]');
                title && self.setTitle(title, true);
                return title;
            };

            // Initialize local object with url from storage
            var loadUrl = function () {
                var url = storageProvider.getItem(keys.compareList + '[Url]');
                url && self.setUrl(url, true);
                return url;
            };

            $.extend(self, {
                compareList: storageProvider.getItem(keys.compareList) || [],
                url: '',
                title: '',
                // update the return url
                setUrl: function (url, fromStorage) {
                    self.url = url;
                    evo.$.body.trigger('returnurlupdated.evo.compare', { url: url, title: self.title, fromStorage: !!fromStorage });
                    !fromStorage && storageProvider.setItem(keys.compareList + '[Url]', url);
                },
                // update the return page title
                setTitle: function (title, fromStorage) {
                    self.title = title;
                    evo.$.body.trigger('returntitleupdated.evo.compare', { url: self.url, title: title, fromStorage: !!fromStorage });
                    !fromStorage && storageProvider.setItem(keys.compareList + '[Title]', title);
                },
                // add a product id to the list
                addProduct: function (id, fromStorage) {
                    if (_.indexOf(self.compareList, id) == -1) {
                        self.compareList.push(id);
                        evo.$.body.trigger('productadded.evo.compare', { id: id, fromStorage: !!fromStorage });
                        !fromStorage && setProducts(self.compareList);
                    }
                },
                // remove a product id from the list
                removeProduct: function (id, fromStorage) {
                    var index = _.indexOf(self.compareList, id);
                    if (index > -1) {
                        self.compareList.splice(index, 1);
                        evo.$.body.trigger('productremoved.evo.compare', { id: id, fromStorage: !!fromStorage });
                        !fromStorage && setProducts(self.compareList);
                    }
                }
            });

            $(function () { loadTitle(); loadUrl(); });

            // Monitor storage to capture changes in other tabs / windows
            // trigger custom event
            $(window).on('storage', function (e) {
                // IE triggers the storage event in the same tab / window as the storage action
                // facepalm
                if (document.hasFocus())
                    return;

                // Only watch changes to CompareList
                if (e.originalEvent.key.split('[')[0] != keys.compareList) {
                    return;
                };

                var val = JSON.parse(e.originalEvent.newValue);

                switch (e.originalEvent.key) {
                    case keys.compareList:
                        if (val && val.data && !_.isEqual(val.data, self.compareList)) {
                            var addedItems = _.difference(val.data, self.compareList),
                                removedItems = _.difference(self.compareList, val.data);
                            _.each(addedItems, function (i) {
                                self.addProduct(i, true);
                            });
                            _.each(removedItems, function (i) {
                                self.removeProduct(i, true);
                            });
                        }
                        break;
                    case keys.compareList + '[Url]':
                        if (val['data'] != self.url) {
                            self.setUrl(val.data, true);
                        }
                        break;
                    case keys.compareList + '[Title]':
                        if (val['data'] != self.title) {
                            self.setTitle(val.data, true);
                        }
                        break;
                }
            });

            instances.listManager = self;
        }
    };
})(jQuery);