// Init countdown timer for HEADBAND
$(function () {
    $('[data-toggle="countdown-timer"]:not("#headband-timer-code .countdown-timer")').each(function () {
        var $this = $(this),
            timeZoneOffset = $this.attr("data-utc-offset"),
            dataEndHour = $this.attr("data-end-hour"),
            dataEndMin = $this.attr("data-end-min"),
            endHours = dataEndHour ? dataEndHour : 23,
            endMinutes = dataEndMin ? dataEndMin : 59,
            endSeconds = dataEndHour || dataEndMin ? 0 : 59,
            endMilSeconds = dataEndHour || dataEndMin ? 0 : 999,
            untilDate = new Date($this.attr("data-enddate"))
        untilDate.setHours(endHours, endMinutes, endSeconds, endMilSeconds);

        // TODO create dynamic way to handle daylight savings time UTC offset -7 PDT or -8 PST
        $this.countdown({ until: $.countdown.UTCDate(timeZoneOffset, untilDate), alwaysExpire: true, onExpiry: isExpired });

        function isExpired() {
            $this.siblings('.headband-timer-title').html('<strong>Has Expired</strong>');
            $this.remove();
        }
    });
    // init for mobile
    if (evo.isMobile) {
        //To support headbands that have show/hide capability that is persisted across pages,
        //we need to listen for the toggle click event as well as use sessionStorage to store the user's selection
        var $headbandWrap = $('#headband-wrapper'),
            $headband = $('#headband');

        $('#headband-toggle').on('click', function (e) {
            e.preventDefault();
            var headbandVisibleKey = 'headband-visible';

            if ($headband.is(':visible')) {
                $(this).removeClass('no-animation');
                $headbandWrap.removeClass('is-open');
                $headband.slideUp(300);
                evo.sessionStorage.setItem(headbandVisibleKey, 'false');
            } else {
                $headbandWrap.addClass('is-open');
                $headband.slideDown(300);
                evo.sessionStorage.setItem(headbandVisibleKey, 'true');
            }
        });

        var headbandVisible = evo.sessionStorage.getItem('headband-visible');
        if (headbandVisible === 'true' || headbandVisible === null) {
            $('#headband-toggle').addClass('no-animation');
            $headband.css('display', 'block');
            $headbandWrap.addClass('is-open');
        }
    }
});