(function register() {
    function registerLocale(locale) {
        numeral.register("locale", locale.code, locale.format);
        numeral.register("locale", locale.code + '-usd', (!locale.usdOverride ? locale.format : locale.usdOverride));
    };

    var enus = {
        code: "en-us",
        format: {
            delimiters: {
                thousands: ",",
                decimal: "."
            },
            abbreviations: {
                thousand: "k",
                million: "m",
                billion: "b",
                trillion: "t"
            },
            currency: {
                symbol: "$"
            }
        }
    };

    var enca = {
        code: "en-ca",
        format: {
            delimiters: {
                thousands: ",",
                decimal: "."
            },
            abbreviations: {
                thousand: "k",
                million: "m",
                billion: "b",
                trillion: "t"
            },
            currency: {
                symbol: "C$"
            }
        }
    };
    enca.usdOverride = {
        ...enca.format,
        currency: {
            symbol: "US$"
        }
    }

    registerLocale(enus);
    registerLocale(enca);

    numeral.defaultFormat("$,0.00");
})();
