// Storage
(function () {
    function DummyStorage() {
        var self = this,
            store = {};

        self.setItem = function (key, value) {
            store[key] = value;
        }

        self.getItem = function (key) {
            return store[key];
        }

        self.removeItem = function (key) {
            delete store[key];
        }
    }

    function EvoStorage(type) {
        var self = this,
            backingStore = type == 'session' ? window.sessionStorage : window.localStorage;

        try {
            if (!backingStore) throw "storage not supported";

            // even if localStorage exists, iOS will throw an exception if
            // in "Private" mode
            backingStore.setItem("storage-availability-test", "data");
            backingStore.removeItem("storage-availability-test");
        } catch (e) {
            backingStore = new DummyStorage();
        }

        self.setItem = function (key, value, expireDate = null, excludeExpiration = false) {
            if (excludeExpiration) {
                return backingStore.setItem(key, JSON.stringify(value));
            }
            var now = new Date(),
                newVal =
                    {
                        '_c': now.toISOString(),
                        '_expire': (expireDate || getExpireDate(now)).toISOString(),
                        'data': value
                    };
            return backingStore.setItem(key, JSON.stringify(newVal));
        };

        self.getItem = function (key, excludeExpiration = false) {

            try {
                var val = JSON.parse(backingStore.getItem(key));
                if (val) {
                    if (excludeExpiration) {
                        return val;
                    } else if (new Date() <= new Date(val['_expire'])) {
                        return val['data'];
                    }
                }
            }
            catch (e) {
            }

            backingStore.removeItem(key);
            return null;
        };

        self.removeItem = function (key) {
            return backingStore.removeItem(key);
        };
    }

    function getExpireDate(now) {
        now = now || new Date();
        now.setFullYear(now.getFullYear() + 1);
        return now;
    }

    evo = evo || {};
    evo.storage = evo.storage || new EvoStorage('local');
    evo.sessionStorage = evo.sessionStorage || new EvoStorage('session');
})();